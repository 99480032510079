@import 'normalize.css';
@import 'styles/breakpoints';
@import 'fonts';
@import 'styles/colors';

html,
body {
  overflow: unset;

  width: 100%;
  min-width: 320px;
  margin: 0;
  padding: 0;

  font-family: 'DM Sans', Arial, sans-serif;
  font-size: 14px;
  color: $white-utility;

  background-color: $background-90;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: 400;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* stylelint-disable-next-line selector-class-pattern */
#carrotquest-messenger-collapsed-container.carrotquest-messenger-right_bottom {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}

@media (min-width: $tablet) {
  /* stylelint-disable-next-line selector-class-pattern */
  #carrotquest-messenger-collapsed-container.carrotquest-messenger-right_bottom {
    margin-right: 13px !important;
    margin-bottom: 13px !important;
  }
}

[dir='ltr'] {
  --icon-direction: 1;
}

[dir='rtl'] {
  --icon-direction: -1;
}

svg {
  transform: scaleX(var(--icon-direction));
}
