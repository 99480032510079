/* dm-sans-regular - latin */
@font-face {
  font-family: 'DM Sans';
  font-weight: 400;
  font-style: normal;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../../public/fonts/dm-sans-v14-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* dm-sans-italic - latin */
@font-face {
  font-family: 'DM Sans';
  font-weight: 400;
  font-style: italic;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../../public/fonts/dm-sans-v14-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* dm-sans-500 - latin */
@font-face {
  font-family: 'DM Sans';
  font-weight: 500;
  font-style: normal;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../../public/fonts/dm-sans-v14-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* dm-sans-500italic - latin */
@font-face {
  font-family: 'DM Sans';
  font-weight: 500;
  font-style: italic;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../../public/fonts/dm-sans-v14-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* dm-sans-600 - latin */
@font-face {
  font-family: 'DM Sans';
  font-weight: 600;
  font-style: normal;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../../public/fonts/dm-sans-v14-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* dm-sans-600italic - latin */
@font-face {
  font-family: 'DM Sans';
  font-weight: 600;
  font-style: italic;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../../public/fonts/dm-sans-v14-latin-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* dm-sans-700 - latin */
@font-face {
  font-family: 'DM Sans';
  font-weight: 700;
  font-style: normal;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../../public/fonts/dm-sans-v14-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* dm-sans-700italic - latin */
@font-face {
  font-family: 'DM Sans';
  font-weight: 700;
  font-style: italic;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../../public/fonts/dm-sans-v14-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
