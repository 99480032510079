@import 'styles/breakpoints';
@import 'styles/colors';

.scrollButton {
  cursor: pointer;

  position: fixed;
  z-index: -1;
  right: 10px;
  bottom: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  padding: 6px;

  color: $orange;

  opacity: 0;
  background-color: $accent-second;
  border: 1px solid $white-utility;
  border-radius: 50%;

  &:hover {
    box-shadow: 0 0 0 2px rgba($accent-second, 0.5);
  }

  &.shown {
    z-index: 10;
    opacity: 1;
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

@include media('big_mobile') {
  .scrollButton {
    right: 16px;
    bottom: 40px;
    left: unset;

    width: 36px;
    height: 36px;
    padding: 10px;
  }
}

@include media('laptop') {
  .scrollButton {
    right: 70px;
    bottom: 60px;
    left: unset;

    width: 48px;
    height: 48px;
    padding: 13px;
  }
}

@include media('desktop') {
  .scrollButton {
    right: 130px;
    bottom: 120px;
    left: unset;

    width: 56px;
    height: 56px;
    padding: 14px;
  }
}
